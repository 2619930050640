export const navigationStore = defineStore('navigation', {
  state: () => {
    return {
      gnb: [
        { name: 'index', label: 'Company', link: '/' },
        { name: 'bonbon', label: 'Works & Contents', link: 'https://art-bonbon.com' },
        { name: 'recruit', label: 'Recruit', link: '/recruit/' },
        { name: 'communication', label: 'Communication', link: '/communication/' },
        { name: 'family', label: 'Family Companies', link: '/family/' },
      ],
      bonbonArt: 'https://art-bonbon.com',
      kakaoChannel: 'https://pf.kakao.com/_pxlxaxms',
      instagram: 'https://www.instagram.com/artbonbon_official',
      contactEmail: 'people1@i-screamarts.com',
      howToWork: 'https://subsequent-dish-34d.notion.site/100e36e965754ee096364903c66f784d',
      pabloarts: 'https://i-screamarts.com/',
      appStore: 'https://apps.apple.com/kr/app/%EC%95%84%ED%8A%B8%EB%B4%89%EB%B4%89/id1581905454',
    }
  },
})
